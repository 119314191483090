import {BrowserClient, BrowserOptions, Hub} from '@sentry/browser'

class Sentry {

  static hub: Hub | null

  static init(options: BrowserOptions) {
    if (this.hub) return
    const client = new BrowserClient(options)
    this.hub = new Hub(client)
  }

  static captureException(exception: any): string {
    return this.hub?.captureException(exception) || ''
  }

  static captureMessage(message: string): string {
    return this.hub?.captureMessage(message) || ''
  }
}

export { Sentry }
